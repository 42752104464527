<template>
  <div class="wap-mine-setting">
    <headerBar :title="$t('heng-fu-she-zhi-0')" @back="back"></headerBar>

    <div class="wap-form">
      <div class="upload-item">
        <div class="label">{{ $t('dian-pu-heng-fu-11920x300') }}</div>
        <div>
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="/api/file/upload"
            name="file"
            :on-success="(file) => uploadSuccess(file, 'banner1')"
            :before-upload="beforeUpload"
          >
            <img
              v-if="form.banner1"
              :src="getBaseUrl(form.banner1)"
              class="avatar"
            />
            <div v-else class="flex-column-center">
              <i
                class="el-icon-camera-solid"
                style="font-size: 24px; color: #aaa"
              ></i>
              {{ $t('tian-jia-heng-fu') }}
            </div>
          </el-upload>
        </div>
      </div>
      <div class="upload-item">
        <div class="label">{{ $t('dian-pu-heng-fu-21920x300') }}</div>
        <div>
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="/api/file/upload"
            name="file"
            :on-success="(file) => uploadSuccess(file, 'banner2')"
            :before-upload="beforeUpload"
          >
            <img
              v-if="form.banner2"
              :src="getBaseUrl(form.banner2)"
              class="avatar"
            />
            <div v-else class="flex-column-center">
              <i
                class="el-icon-camera-solid"
                style="font-size: 24px; color: #aaa"
              ></i>
              {{ $t('tian-jia-heng-fu-0') }}
            </div>
          </el-upload>
        </div>
      </div>
      <div class="upload-item">
        <div class="label">{{ $t('dian-pu-heng-fu-31920x300') }}</div>
        <div>
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="/api/file/upload"
            name="file"
            :on-success="(file) => uploadSuccess(file, 'banner3')"
            :before-upload="beforeUpload"
          >
            <img
              v-if="form.banner3"
              :src="getBaseUrl(form.banner3)"
              class="avatar"
            />
            <div v-else class="flex-column-center">
              <i
                class="el-icon-camera-solid"
                style="font-size: 24px; color: #aaa"
              ></i>
              {{ $t('tian-jia-heng-fu-1') }}
            </div>
          </el-upload>
        </div>
      </div>

      <div class="form-btn">
        <Button @click="submit">{{ $t('ti-jiao') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { shopSetting, shopInfo } from '@/api/shop'
import headerBar from '@/components/header'
import { Field, Button } from 'vant'
import { getToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    headerBar,
    Field,
    Button
  },
  data() {
    return {
      form: {
        banner1: '',
        banner2: '',
        banner3: ''
      },
      headers: {},
      shopInfo: {}
    }
  },
  computed: {
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    uploadSuccess() {},
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    back() {
      this.$router.go(-1)
    },
    init() {
      shopInfo().then((res) => {
        let data = res.data
        this.shopInfo = res.data
        this.form = {
          banner1: data.Banner,
          banner2: data.Banner1,
          banner3: data.Banner2
        }
      })
    },
    submit() {
      if (!this.form.banner1 && !this.form.banner2 && !this.form.banner3) {
        this.$toast(this.$t('qing-shang-chuan-heng-fu-tu-pian'))
        return
      }
      shopSetting({
        ...this.shopInfo,
        Banner: this.form.banner1,
        Banner1: this.form.banner2,
        Banner2: this.form.banner3
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('xiu-gai-cheng-gong'))
          setTimeout(() => {
            this.back()
          }, 1500)
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>